<template>
    <div class="TravelView">
        <loading-component></loading-component>
        <nav-bar-component :navbar="nav_bar" :title_name="my_nav_bar_name" @show_pp="show_popup = true"></nav-bar-component>
        <van-popup
            v-model="show_popup"
            position="top"
            round
            :close-on-click-overlay="false"
            closeable   
        >
            <van-form :key="form_key">
                <div class="search_title">
                    <van-tag round type="success" size="large">条件搜索区</van-tag>
                </div>
                <van-field
                    v-model="cond.name"
                    name="name"
                    label="差旅单号"
                    placeholder="差旅单号"
                />
                <select-component
                    name="state"
                    lable="审核状态"
                    :farr="this.$store.state.state"
                    @clk="confirm_state"
                ></select-component>
                <select-component
                    name="use_state"
                    lable="使用状态"
                    :farr="[{id: 0,name: '未使用'},{id: 1,name: '已使用'}]"
                    @clk="confirm_use_state"
                ></select-component>
                <select-component
                    name="contain_entertain"
                    lable="是否需要招待"
                    :farr="[{id: 0,name: '不需要'},{id: 1,name: '需要'}]"
                    @clk="confirm_contain_entertain"
                ></select-component>
                <select-component
                    name="enterprise_id"
                    lable="企业"
                    :farr="all_enterprise"
                    @clk="confirm_enterprise"
                ></select-component>
                <select-component
                    name="department_id"
                    lable="部门"
                    :farr="all_department"
                    @clk="confirm_department"
                ></select-component>
                <select-component
                    name="staff_id"
                    lable="申请人"
                    :farr="all_staff"
                    @clk="confirm_staff"
                ></select-component>
                <van-field
                    v-model="cond.title_content"
                    name="title_content"
                    label="标题或内容"
                    placeholder="标题或内容"
                />
                <div class="search_button">
                    <van-button round block type="info" icon="search" @click="search">搜 索</van-button>
                </div>
                <div class="search_button">
                    <van-button round block type="warning" @click="reset">重 置</van-button>
                </div>
                <div class="search_button">
                    <van-button round block type="default" @click="show_popup = false">取 消</van-button>
                </div>
            </van-form>
        </van-popup>
        <div class="insert">
            <van-button v-if="this.$_has('新增差旅申请')" icon="add-o" type="primary" round plain size="small" @click="show_add">创建差旅单</van-button>
        </div>
        <van-list
            v-model="list_loading"
            :finished="finished"
            finished-text="我是有底线的"
            @load="onLoad"
        >
            <van-cell-group inset v-for="v in list" :key="v.name" @click="show_details(v)">
                <van-cell>
                    <template #title>
                        <span class="title_span">{{v.title}}</span>
                    </template>
                    <template #right-icon>
                        <van-tag plain color="#ADADAD" v-if="v.state===0">待提交</van-tag>
                        <van-tag plain type="primary" v-if="v.state===1">审批中</van-tag>
                        <van-tag plain type="success" v-if="v.state===2">已通过</van-tag>
                        <van-tag plain type="danger" v-if="v.state===3">已驳回</van-tag>
                        <span>&nbsp;</span>
                        <van-tag round color="#ADADAD" v-if="v.use_state===0">未使用</van-tag>
                        <van-tag round type="success" v-else-if="v.use_state===1">已使用</van-tag>
                    </template>
                </van-cell>
                <van-cell class="note_cell" title="单号" :value="v.name" :border="false" />
                <van-cell class="note_cell" title="出差日期" :value="v.start_date" :border="false" />
                <van-cell class="note_cell" title="出差天数" :value="v.days+'天'" :border="false" />
                <van-cell class="note_cell" title="目的地" :value="v.address_name" :border="false" />
                <van-cell title="是否需要招待">
                    <template #right-icon>
                        <van-tag plain color="#d0d0d0" v-if="v.contain_entertain===0">不需要</van-tag>
                        <van-tag plain type="success" v-if="v.contain_entertain===1">需要</van-tag>
                    </template>
                </van-cell>
                <van-cell class="note_cell" title="申请金额" :value="v.money | diy_money" :border="false" />
                <van-cell class="note_cell" title="申请人" :value="v.staff_name" :border="false" />
                <van-cell class="note_cell" title="当前审批人" :value="v.current_examine_staff_name" :border="false" />
            </van-cell-group>
        </van-list>
    </div>
</template>

<script>
import LoadingComponent from '@/components/common/LoadingComponent.vue'
import NavBarComponent from '@/components/common/NavBarComponent.vue'
import SelectComponent from '@/components/common/SelectComponent.vue'

import { travel_index_request } from '@/network/finance/Travel.js'
import { enterprise_list_request,department_list_request,staff_list_request } from '@/network/list.js'

import { fomatFloat,thousandBitSeparator } from '@/assets/js/common.js'

export default {
    name:'TravelView',
    data(){
        return {
            nav_bar: {
                left: true,
                right: {
                    type: 'text',
                    name: '搜索'
                },
            },
            my_nav_bar_name: '差旅单管理',
            list: [],
            total: 0,
            list_loading: false,
            finished: false,
            show_popup: false,
            form_key: Date.now(),
            cond: {
                name: '',
                state: '',
                use_state: '',
                enterprise_id: '',
                department_id: '',
                staff_id: '',
                title_content: '',
                page: 1
            },
            all_enterprise: [],
            all_department: [],
            all_staff: []
        }
    },
    computed:{},
    methods:{
        get_all_enterprise() {
            this.$store.commit('true_loading')
            enterprise_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_enterprise = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_all_staff() {
            this.$store.commit('true_loading')
            staff_list_request()
                .then((s) => {
                    if (s.status === 0) {
                        this.all_staff = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        get_travel() {
            this.list = []
            this.finished = false
            this.$store.commit('true_loading')
            travel_index_request(this.cond)
                .then((s) => {
                    if (s.status === 0) {
                        this.list = s.result.data
                        this.total =s.reuult.total
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    // this.$toast.fail(err);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_state(e) {
            this.cond.state = e.id
        },
        confirm_contain_entertain(e) {
            this.cond.contain_entertain = e.id
        },
        confirm_use_state(e) {
            this.cond.use_state = e.id
        },
        confirm_enterprise(e) {
            this.cond.enterprise_id = e.id
            this.cond.department_id = ''
            department_list_request({enterprise_id: e.id})
                .then((s) => {
                    if (s.status === 0) {
                        this.all_department = s.result
                    } else {
                        this.$toast.fail(s.msg);
                    }
                })
                .catch((err) => {
                    this.$toast.fail(s.msg);
                })
                .finally(() => this.$store.commit('false_loading'))
        },
        confirm_department(e) {
            this.cond.department_id = e.id
        },
        confirm_staff(e) {
            this.cond.staff_id = e.id
        },
        search() {
            this.cond.page = 1
            this.get_travel()
            this.show_popup = false
        },
        reset() {
            this.cond = {
                name: '',
                state: '',
                use_state: '',
                enterprise_id: '',
                department_id: '',
                staff_id: '',
                title_content: '',
                page: 1
            }
            this.form_key = Date.now()
        },
        show_details(v) {
            if(this.$_has('差旅申请详情')) {
                this.$router.push({
                    name: 'TravelDetails',
                    query: {
                        id: v.id
                    }
                })
            }
        },
        show_add() {
            this.$router.push({
                name: 'TravelDetails',
                query: {
                    id: 0
                }
            })
        },
        onLoad() {
            setTimeout(() => { 
                this.cond.page++
                travel_index_request(this.cond)
                    .then((s) => {
                        if (s.status === 0) {
                            this.total = s.result.total
                            s.result.data.forEach(v => {
                                this.list.push(v)
                            });
                        } else {
                            this.$toast.fail(s.msg);
                        }
                    })
                    .catch((err) => {
                        this.$toast.fail(s.msg);
                    })
                    .finally(() => {
                        // 加载状态结束
                        this.list_loading = false;

                        // 数据全部加载完成
                        if (this.list.length >= this.total) {
                            this.finished = true;
                        }
                    })
            }, 500);
        },
    },
    filters:{
        diy_money(v) {
            return '￥ '+thousandBitSeparator(fomatFloat(v))
        }
    },
    props:{},
    created(){
        this.get_travel()
        this.get_all_enterprise()
        this.get_all_staff()
    },
    destoryed(){},
    mounted(){},
    updated(){},
    components:{
        LoadingComponent,
        NavBarComponent,
        SelectComponent
    },
    watch:{}
}
</script>

<style>
.TravelView .van-cell-group {
    margin-bottom: 0.2rem;
}
.TravelView .title_span {
    font-weight: bold;
    color: #0080FF;
}
.TravelView .note_cell {
    padding: 0.1rem 0.42667rem
}
.TravelView .search_title {
    text-align: center;
}
.TravelView .search_button {
    margin: 0.5rem;
}
.TravelView .insert {
    text-align: center;
    /* position: absolute; */
        transform: translateY(-40%);
}
</style>